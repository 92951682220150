import Seo from 'src/components/seo/Seo'

import NatalComponent from './components'
import './styles.scss'

function natal() {
  return (
    <>
      <Seo
        title="Natal na Decathlon - Presentes com até 60% de Desconto"
        description="Natal na Decathlon! Compre roupas, calçados, equipamentos, acessórios, artigos esportivos e presentes com até 60% de desconto"
      />
      <NatalComponent />
    </>
  )
}

export default natal
